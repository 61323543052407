.loginWrapper{
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.50), rgba(255, 255, 255, 0.50)), url("../Images/overlay-1.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    padding-bottom: 20px;
}
.loginCard{
    width: 65%;
    margin: auto;
    background-color: #fff;
    min-height: 50px;
    margin-top: 25px;
    border-radius: 4px;
}
.loginBlock{
    width: 50%;
}
.loginBlock img{
    object-fit: cover;
}
.font_18{
    font-size: 18px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #47484a;
    margin-top: 0;
}
.text_muted{
    font-size: 15px;
}
.loginForm{
    text-align: left;
    margin-top: 50px;
    width: 80%;
    margin: auto;
}
input:focus{
    outline: none !important;
}
.loginLabel{
    position: relative;
    left: 14px;
    top: 11px;
    line-height: 20px;
    background: #fff;
    margin: 0;
    padding: 0 7px;
    z-index: 1;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #47484a;
    border-radius: 2px;
    font-size: 14px;
}
.loginLabel2{
    padding-left: 10px !important;
    font-size: 0.8rem;
    margin: 0;
    padding: 0 7px;
    z-index: 1;
    font-weight: 500;
    color: #47484a;
    
}

.login_input {
    display: block;
    width: 100%;
    padding: 0.8rem 1.2rem;
    font-size: .85rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d3d6da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.form-check-input:focus {
    border-color: none;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.15rem rgba(12,118,138,.25) !important;
    box-shadow: 0 0 0 0.15rem rgba(12,118,138,.25) !important;
}
.form-check-input:checked {
    background-color: rgb(84, 159, 173) !important;
    border-color: rgb(84, 159, 173) !important;
}

@media (max-width:1191px) {
    .loginCard{
        width: 90%;
    }
}

@media (max-width:768px) {
    .loginCard{
        width: 95%;
    }
    .loginBlock{
        width: 100%;
    }
    .loginForm{
        width: 95%;
        padding-bottom: 50px;
    }
}