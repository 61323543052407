* {
  margin: 0;
}

body {
  font-family: 'Rubik', sans-serifm !important;
}

a {
  text-decoration: none !important;
}

select,
input:focus {
  outline: none !important;
}

.myrow {
  display: flex;
  flex-wrap: wrap;
}

.align_center {
  align-items: center;
}

.justify_space_between {
  justify-content: space-between;
}

.text_muted {
  color: #83838d;
}

.solid_btn {
  padding: 0.75rem 0.47rem;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  border: none !important;
  background-color: #0c768a;
  font-weight: 400;
  letter-spacing: 0.2px;
  transition: 0.2s;
  cursor: pointer;
}

.solid_btn:hover {
  background-color: #0b6d7f;
  transition: 0.2s;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.2;
  filter: alpha(opacity=10);
  z-index: 99999 !important;
}

.badge {
  font-weight: 450 !important;
  line-height: normal !important;
  border-radius: 1px !important;
  font-size: 10.5px !important;
}

.nHeight {
  --bs-progress-height: 0.4rem !important;
}
.progress-stacked {
  --bs-progress-height: 0.7rem !important;
}

.css-60m3qz-menu{
  z-index: 999 !important;
}

@media (max-width:850px) {
  .desktop {
    display: none !important;
  }
}

@media (min-width:850px) {
  .mobile {
    display: none !important;
  }
}

.dis_block {
  display: block !important;
  overflow: hidden !important;
  margin-bottom: 5px !important;
}

.swal2-container{
  z-index: 99999999 !important;
}