.business_block1{
    width: 63%;
}
.business_block2{
    width: 35%;
}

.dash_top_block{
    width: 23%;
    margin-bottom: 30px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 3px;
    transition: 0.3s;
}
.ico_bg{
    padding: 15px;
    border-radius: 4px;
    background-color: rgba(12,118,138,.15);
    margin-right: 15px;
}

.dash_block_3{
    width: 32%;
    margin-bottom: 30px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 3px;
    transition: 0.3s;
    min-height: 200px;
    height: 480px;
    overflow: auto;
}
.ww100:first-child{
    width: 60%;
}
.ww100{
    width: 40%;
}

@media (max-width:1191px) {
    .business_block1{
        width: 100%;
    }
    .business_block2{
        width: 100%;
        margin-top: 30px;
    }
}

@media (max-width:768px){
    .ww100,.ww100:first-child{
        width: 100%;
    }
    .dash_top_block{
        width: 95%;
        margin: auto;
        margin-bottom: 20px;
    }
    .dash_block_3{
        width: 95%;
        margin: auto;
        margin-bottom: 20px;
    }
}