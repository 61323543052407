.font-30{
    font-size: 30px !important;
}
.detail_block{
    width: 32%;
    margin-bottom: 5px;
}
.detail_block .localSearchInput{
    padding-left: 10px;
    font-size: 12px;
}
.detail_block .modal_input{
    font-size: 12px;
}

.followUp_message{
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 2px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.add_inputs{
    margin-left: 10px;
    margin-top: -6px;
    color: #0C768A;
    cursor: pointer;
    padding: 1px;
    font-size: 20px;
}
.add_inputs2{
    margin-left: 10px;
    margin-top: -6px;
    color: #ED5E49;
    cursor: pointer;
    padding: 1px;
    font-size: 20px;
}

@media (max-width:800px) {
    .detail_block{
        width: 100%;
    }
}