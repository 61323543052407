.header {
    padding: 0px 25px;
    position: sticky;
    top:0;
    z-index: 999 ;
    margin-bottom: 10px;
}

.page-title {
    font-size: 20px;
    font-weight: 500;
    padding: 20px 0px;
}

.globalSearchInput {
    border: none;
    height: 38px;
    padding-left: 40px;
    padding-right: 20px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 30px;
    font-size: 13px;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, .04);
    box-shadow: 0 2px 3px rgba(0, 0, 0, .04);
    transition: 0.3s;
}

.ico-search span {
    position: absolute;
    z-index: 10;
    font-size: 15px;
    line-height: 38px;
    left: 13px;
    top: 5;
    transition: 0.3s;
}

.headerIcon {
    font-size: 30px !important;
    height: 68px;
    width: 42px;
    padding: 9px ;
    cursor: pointer;
    transition: 0.3s;
}

input:focus {
    outline: none !important;
}

@keyframes dropDownSlide {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

.more_block {
    z-index: 999 !important;
    right: 0;
    margin-top: 80px;
    position: absolute;
    width: 320px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
    inset: 0px 0px auto auto;
    transition: 0.3s;
    animation: dropDownSlide 0.3s;
    transform: translateY(-10px);
}
.more_block2 {
    z-index: 9999 !important;
    margin-right: -205px;
    position: absolute;
    width: 180px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
    inset: 0px 0px auto auto;
    transition: 0.3s;
    animation: dropDownSlide 0.3s;
    transform: translateY(-10px);
}
.small_link{
    font-size: 80%;
    color: #0C768A;
    cursor: pointer;
    transition: 0.2s;
}
.small_link:hover{
    text-decoration: underline;
    transition: 0.2s;
}
.notificationBlock{
    height: 300px;
    padding-bottom: 10px;
}

@media (max-width:850px) {
    .header{
        padding: 0px 0px;
    }
    
}