.layoutWrapper{
    display: flex;
    flex-direction: row;
}
.main-body{
   min-height: 100vh;
   margin-left: 200px;
   width: calc(100% - 200px);
   transition: 0.3s;
}

.content-wrapper{
    padding: 0px 25px;
}
.resize_none{
    resize: none;
}

.react-toast-notifications__toast__content{
    white-space: pre-wrap !important;
}

@media (max-width:850px) {
    .main-body{
        margin-left: 0px !important;
        width: 100%;
    }
    .content-wrapper{
        padding: 0px 10px;
    }
}