.email_sidebar {
    width: 244px;
    min-height: 83vh;
}

.pp {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.email_main_body {
    flex-grow: 1;
    margin-left: 15px;
}

.email_nav_wrapper {
    margin-top: 20px;
    padding: 5px 10px;
}

.email-menu-item {
    width: 100%;
    padding: 12px 0px;
    letter-spacing: .02em;
    cursor: pointer !important;
    font-size: 14px;
    font-weight: 400;
    font-family: Rubik, sans-serif;
    margin-left: 0px;
    /* transition: 0.3s; */
    color: #83838d;
}

.email-menu-item-active {
    transition: 0.3s;
    color: #ED5E49 !important;
    font-weight: 450 !important;
}

.left_border_radius {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.right_border_radius {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

/* ----------------------------------Inbox Css Start--------------------------- */

.emailList {
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    padding: 0px 5px;
    /* padding: 15px 5px; */
    /* background-color: rgba(0, 0, 0, 0.09); */
    border-radius: 2px;
    
    transition: 0.3s;
}

.emailList p{
    margin-bottom: 0 !important;
}
.emailList_active p{
    margin-bottom: 0 !important;
}

.emailList:hover{
    background-color: rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}
.emailList_active {
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    padding: 0px 5px;
    /* padding: 15px 5px; */
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    
}

.emailListSubject {
    font-weight: 450;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 450px;
    /* opacity: 0.9; */
    letter-spacing: 0.4px;
    text-transform: capitalize;
}

.emailListContent {
    font-weight: 400;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 3px;
    max-width: 30vw;
    display: flex;
    align-content: center;
}

.emailListWrapper {
    flex-grow: 1;
    padding: 15px 0px;
    cursor: pointer;
}

.emailListDate {
    font-size: 13px;
    font-weight: 400;
    width: 120px;
    text-align: center;
    padding: 15px 5px;
    cursor: pointer;
}

.emailListLabel {
    font-size: 11px;
    padding: 2px 4px;
    padding-bottom: 4px;
    border-radius: 4px;
    color: #fff;
    margin-right: 6px;
}

.mycard{
    margin: 10px;
    text-align: center;
    width: 150px;
    padding-top: 20px;
    padding-bottom: 15px;
}

.email_download_link{
    color: #0c768a;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    transition: 0.3s;
}
.email_download_link:hover{
    color: cornflowerblue;
    transition: 0.3s;
}
.email_file_name{
    text-align: center;
    margin-top: 15px;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis !important;
    width: 140px;
    padding: 0px 10px;
    overflow: hidden;
}

.replyCard{
    padding: 10px 15px;
    padding-bottom: 0px;
    margin-bottom: 20px;
    border-radius: 8px;
}